import {Box, Center, Image, Link, Text} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CodeField from '../../components/CodeField/CodeField';
import NavigateBar from '../../components/NavigateBar';
import PrimaryButton from '../../components/PrimaryButton';
import SmallModalMinimalist from '../../components/SmallModalMinimalist';
import {LOGIN_TYPES} from '../../constants/codeConstants';
import images from '../../res/images';
import {isWeb} from '../../helpers/utils/utils';

/**
 * Screen para agregar el código de acceso por teléfono o email
 */

export default function Code({
  lwc,
  showModalLogin,
  onSubmit,
  onRequestCode,
  onBack,
  isRecovery,
  onSubmitRecovery,
  onRequestEmailCode,
  loading,
  params,
  commonStylesWeb,
}) {
  const {topBarLogo} = images;
  const {RECOVERY_PHONE} = LOGIN_TYPES;
  const {t, i18n} = useTranslation();
  const [value, setValue] = useState('');
  const [linkDisabled, setLinkDisabled] = useState(true);
  const [counter, setCounter] = useState(60);
  const [otherCode, setOtherCode] = useState(false);
  const {type, email, phone} = params || {};
  const [showModal, setShowModal] = useState(showModalLogin);

  const message = isRecovery
    ? otherCode
      ? type === RECOVERY_PHONE
        ? t('login:sendCodeWha')
        : t('login:sendOtherCodeEmail')
      : type === RECOVERY_PHONE
      ? t('login:sendCodePhoneModal')
      : t('login:sendCodeEmailModal')
    : !lwc
    ? t('login:sendCodeEmailRegister')
    : t('login:sendCodePhoneRegister');

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => {
        setCounter(prevCounter => prevCounter - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setLinkDisabled(false);
    }
  }, [counter]);

  const formatTime = time => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(1, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleLinkClick = () => {
    if (!linkDisabled) {
      console.log('handleLinkClick');
      setValue('');
      setOtherCode(true);
      setShowModal(true);
      setCounter(60);
      setLinkDisabled(true);
    }
  };

  function validateCode() {
    return value?.length == 6;
  }

  function submit() {
    if (isRecovery) {
      onSubmitRecovery(value, type);
      return;
    }
    onSubmit(value);
  }

  const loginText = lwc ? t('login:sendCodePhone') : t('login:sendCodeEmail');

  return (
    <Box mt={isWeb() ? 20 : 0}>
      <NavigateBar
        backEvent={onBack}
        headerTitle={
          <Image
            size={'xs'}
            resizeMode={'contain'}
            alt={'logo'}
            source={topBarLogo}
          />
        }
      />
      {/* Titulo */}
      <Box mt={10} alignItems="center">
        <Text
          w={'80%'}
          mt={1}
          fontFamily={isWeb() ? 'Recoleta' : 'recoleta'}
          fontWeight={800}
          textAlign="center"
          color="primary.100"
          fontSize={'3xl'}
          // bold={true}
        >
          {t('login:writeCode')}
        </Text>
        <Text
          mb={10}
          w={'70%'}
          textAlign="center"
          color={'tertiary.600'}
          fontSize="md">
          {loginText}
        </Text>
      </Box>
      <CodeField
        value={value}
        setValue={setValue}
        testID={'verification-code-input'}
      />
      <Box px={4} mt={2}>
        <Text
          mt={30}
          fontSize={'md'}
          color={'tertiary.600'}
          alignSelf={'center'}
          testID={'middle-id-code'}>
          {t('login:inputCodeHelper')}
        </Text>
        <Text
          mt={2}
          fontSize={'md'}
          color={'tertiary.600'}
          alignSelf={'center'}>
          {`${t('login:wait')}${formatTime(counter)} s`}
        </Text>
        {lwc ? (
          <Center>
            <Link
              isDisabled={linkDisabled}
              color={linkDisabled ? 'tertiary.10' : 'tertiary.600'}
              isUnderlined={false}
              testID={'on-request-code-wa'}
              onPress={() => {
                handleLinkClick();
                onRequestCode('wa');
              }}>
              {t('login:whatsappCodeLabel')}
            </Link>
          </Center>
        ) : (
          <Center>
            <Link
              isDisabled={linkDisabled}
              color={linkDisabled ? 'tertiary.5' : 'tertiary.600'}
              isUnderlined={false}
              testID={'on-request-email'}
              onPress={() => {
                handleLinkClick();
                onRequestEmailCode(email, type, i18n.language);
              }}>
              {t('login:emailCodeAgain')}
            </Link>
          </Center>
        )}
        <Box px={3}>
          <PrimaryButton
            testId={'submit-button-code'}
            onPress={submit}
            extraParams={{
              isDisabled: !validateCode(),
              isLoading: loading,
              mt: 12,
              ...commonStylesWeb,
            }}>
            {t('login:buttonConfirm')}
          </PrimaryButton>
        </Box>
        <SmallModalMinimalist
          testID={'small-modal'}
          message={message}
          showModal={showModal}
          size="xl"
          setShowModal={setShowModal}>
          <Box>
            <Text
              w={'100%'}
              textAlign={'center'}
              adjustsFontSizeToFit={true}
              fontSize={'md'}
              color={'black.40'}>
              {message}
            </Text>
            <Text
              w={'100%'}
              textAlign={'center'}
              fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}
              adjustsFontSizeToFit={true}
              fontSize={'md'}
              fontWeight={800}
              color={'black.40'}>
              {lwc ? phone?.number : email}
            </Text>
            <Text fontSize={'md'} color={'black.40'} textAlign={'center'}>
              {isRecovery ? t('login:recoverPass') : t('login:verifyAccount')}
            </Text>
          </Box>
        </SmallModalMinimalist>
      </Box>
    </Box>
  );
}
