import {
  defaultTheme,
  IconButton,
  Provider,
  TextInput,
} from '@react-native-material/core';
import {Box, Text, View, Button} from 'native-base';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {colors} from '../../styles/colors';
import Icon from '../Icon/Icon';
import EyeCloseIcon from '../icons/EyeCloseIcon';
import EyeOpenIcon from '../icons/EyeOpenIcon';
import IconIon from '../Icon/IconIon';
import {isWeb} from '../../helpers/utils/utils';
/*
 * label: string
 * helperText: string
 * inValidMessage: string
 * input: string
 * setInput: function
 * valid: boolean - ps
 * setValid: function
 * */

const InputForm = ({
  label,
  helperText,
  input,
  setInput = () => {},
  valid,
  isEmail,
  testID,
  showWarning = true,
  showIcon = true,
  extraParams = {},
  isCode = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTextChange = text => {
    setInput(text);
  };

  useEffect(() => {
    handleTextChange(input);
  }, []);

  return (
    <Box w={'100%'} flex={'1'} {...extraParams}>
      <Provider
        theme={{
          // extend the default theme
          ...defaultTheme,
          shapes: {
            medium: {
              borderTopStartRadius: 10,
              borderTopEndRadius: 10,
              borderBottomStartRadius: 10,
              borderBottomEndRadius: 10,
            },
          },
          typography: {
            subtitle1: {
              fontFamily: 'Quicksand',
              fontSize: 15,
            },
            subtitle2: {
              fontFamily: 'Quicksand',
              fontSize: 15,
            },
          },
        }}>
        <TextInput
          testID={testID}
          autoCapitalize={'none'}
          autoCorrect={false}
          textContentType={'oneTimeCode'}
          secureTextEntry={!isEmail && !showPassword}
          style={{width: '100%'}}
          value={input}
          color={colors.purpleGray}
          inputStyle={{
            paddingVertical: 2,
            textAlignVertical: 'center',
            fontFamily: 'Quicksand',
            fontWeight: '600',
            fontSize: 18,
            outline: 0,
          }}
          onChangeText={handleTextChange}
          variant={'outlined'}
          label={label}
          trailing={
            (!isEmail || input !== '') && !isWeb() && showIcon ? (
              <IconButton
                testID={'icon-password-input'}
                onPress={() => setShowPassword(!showPassword)}
                icon={props =>
                  isCode ? null : isEmail ? (
                    <Icon
                      name={valid ? 'checkcircle' : 'closecircle'}
                      size={20}
                      color={valid ? colors.babyBlue200 : colors.warning}
                    />
                  ) : showPassword ? (
                    <EyeOpenIcon />
                  ) : (
                    <EyeCloseIcon />
                  )
                }
              />
            ) : (
              !isEmail && (
                <IconIon
                  onPress={() => setShowPassword(!showPassword)}
                  name={showPassword ? 'eye-outline' : 'eye-off-outline'}
                  size={25}
                />
              )
            )
          }
        />
        {showWarning && (
          <Box
            testID="warning-label"
            style={{alignSelf: 'flex-start'}}
            flex={1}
            py={1}
            px={3}
            borderRadius={'md'}
            bgColor={valid ? colors.transparent : colors.warning}>
            <Text
              textAlign={'left'}
              color={!valid ? colors.red : colors.black40}
              fontSize={'xs'}>
              {helperText}
            </Text>
          </Box>
        )}
      </Provider>
    </Box>
  );
};

InputForm.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  input: PropTypes.string,
  setInput: PropTypes.func,
  valid: PropTypes.bool,
  confirmPassword: PropTypes.bool,
  isEmail: PropTypes.bool,
  testID: PropTypes.string,
};

export default InputForm;
