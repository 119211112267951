import {logEvent, resetRudder} from './analytics/fbAnalytics';
import {singOut} from '../core/firebase/firebaseAuth';
import {
  navigateTo,
  ROUTE_NAMES,
  getCurrentRoute,
} from '../navigation/MainNavigator';
import {clearItems} from '../hooks/useAsyncStorageData';
import {userInitialize} from '../hooks/SessionContext';

async function logoutWithoutHooks(clientApollo, user, setUser, setSession) {
  try {
    console.log('deslogueaaaaa');
    await logEvent('ProfileLogout', {
      user: user?.userFB?.email?.includes('donotsend.com')
        ? user?.userFB?.user?.phoneNumber
        : user?.userFB?.email,
    });
    await singOut();
  } catch (e) {
    console.log('error al cerrar sesion', e);
  }

  await clearItems([]);
  console.log('getCurrentRoute', getCurrentRoute());

  if (getCurrentRoute() !== ROUTE_NAMES?.onboarding) {
    navigateTo(ROUTE_NAMES?.onboarding, {}, true);
    return;
  }

  resetRudder();
  if (clientApollo) {
    await clientApollo.clearStore();
  }
  setSession({...initialize});
  setUser({...userInitialize});
}

export default logoutWithoutHooks;
