/* eslint-disable react-hooks/exhaustive-deps */
/* istanbul ignore file */
import {useMutation} from '@apollo/client';
import {useRoute} from '@react-navigation/native';
import React, {useContext, useEffect, useState} from 'react';
import BackgroundGradient from '../../components/Background/BackgroundGradient';
import MessageSystem from '../../components/MessageSystem';
import {LOGIN_SCREENS, LOGIN_TYPES} from '../../constants/codeConstants';
import {
  createUserByEmailAndPassword,
  onSessionChanged,
  setConfirmCode,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from '../../core/firebase/firebaseAuth';
import {
  changeEmailPassword,
  authSingleEmployeeLogin,
  checkCallCenterCode,
  checkPolicy,
  createUser,
  isRegisterAndHavePassword,
  loginWithPhoneAndPassword,
  requestCall,
  savePassword,
  sendVerificationEmail,
  verifyEmailCode,
} from '../../core/loginCMSServices';
import {refreshToken} from '../../graphql/auth';
import {UPDATE_PRICING_FLOW} from '../../graphql/queries/users';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';
import {
  PushStateContext,
  RemoteConfigContext,
  SessionContext,
  UserContext,
} from '../../hooks/SessionContext';
import Code from './Code';
import EntryLogin from './EntryLogin';
import NewPassword from './NewPassword';
import PasswordRecovery from './PasswordRecovery';
import Register from './Register';
import {
  logEvent,
  logMainScreen,
  logRudderEvent,
  logScreen,
} from '../../helpers/analytics/fbAnalytics';
import {useTranslation} from 'react-i18next';
import {navigateTo, ROUTE_NAMES} from '../../navigation/MainNavigator';
import {isWeb} from '../../helpers/utils/utils';
import EntryEmployeeLogin from './EntryEmployeeLogin';
import {checkCompanyPrefix} from '../../core/cmsServices';
import AsyncStorage from '@react-native-async-storage/async-storage';

/**
 * Screen más importante. Alberga todos los posibles flujos de login/registro
 *
 */
const screens = LOGIN_SCREENS;

export default function Login({route, navigation}) {
  const {t} = useTranslation();
  const {defaultStep, from, registerPrefix, id, goTherapy, customToken} =
    route?.params || {};
  const {session, setSession} = useContext(SessionContext);
  const {user, setUser} = useContext(UserContext);
  const {pushState} = useContext(PushStateContext);
  /** states about login behaviour */
  const [step, setStep] = useState(
    defaultStep ||
      (registerPrefix ? screens.LOGIN_EMPLOYEE_NUMBER : screens.REGISTER),
  );
  const [lwc, setLwc] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isRecovery, setIsRecovery] = useState(false);
  const [type, setType] = useState(null);
  const {
    remoteConfig: {pricingFlow, anonymousOnBoarding},
  } = useContext(RemoteConfigContext);
  const {i18n} = useTranslation();
  /** states about data */
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [enterpriseCode, setEnterpriseCode] = useState(null);
  const [isB2B, setIsB2B] = useState(false);
  const [password, setPassword] = useState(null);
  const [callCode, setCallCode] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const [updatePricingFlow] = useMutation(UPDATE_PRICING_FLOW);
  const [isUserFromV1, setIsUserFromV1] = useState(false);
  const [passwordFromV1, setPasswordFromV1] = useState(null);
  const [showModalLogin, setShowModalLogin] = useState(true);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isWarning, setIsWarning] = useState(false);
  const [userNotFound, setUserNotFound] = useState(false);
  const [codeRecovery, setCodeRecovery] = useState(null);

  const anonymousLoginStep = from === 'onBoarding' ? '1' : '8';

  useEffect(() => {
    // function async that can be called from useEffect
    const init = async () => {
      // check if user is logged in with session.authenticated and user
      if (
        session?.authenticated &&
        user?.userFB?.uid &&
        !user?.userFB?.isAnonymous
      ) {
        resetNavigation();
        console.log('🦞', session?.authenticated);
        return;
      }

      let registerPrefix = await AsyncStorage.getItem('registerPrefix');
      console.log('registerPrefix: ', registerPrefix);
      console.log('navigation');
      console.log(route);

      if (registerPrefix?.length > 0 && route.name === ROUTE_NAMES.login) {
        // await AsyncStorage.removeItem('registerPrefix');
        await navigateTo(ROUTE_NAMES.employeeLogin, {registerPrefix});
        console.log('🦞', session?.authenticated);
        return;
      }

      await logMainScreen('loginOrCreate', pushState);

      await logRudderEvent({
        name: 'anonymousLogin',
        step: `${anonymousLoginStep}.A Login`,
        screen: from,
        pushState: pushState,
        extraProperties: {
          type: step,
        },
      });
    };
    init().then();

    if (registerPrefix) {
      checkCompanyPrefix(registerPrefix).then(isValidPrefix => {
        if (isValidPrefix) {
          console.log('Empresa válida');
        } else {
          setStep(defaultStep || screens.REGISTER);
          console.log('Empresa NO válida');
        }
      });
    }
    if (customToken) {
      console.log('🦞', customToken);
      handleExternalAuth({customToken});
    }
    return () => {
      setConfirm(null);
      setStep(defaultStep || screens.REGISTER);
    };
  }, []);

  useEffect(() => {
    const subscriber = onSessionChanged(onAuthStateChanged);
    return () => {
      subscriber();
    };
  }, [step, lwc, isRecovery]);

  async function back() {
    await navigateTo('goBack', {});
  }

  function handleMessageModal(warning, showMessage, messageText) {
    setIsWarning(warning);
    setShowModalMessage(showMessage);
    setMessage(messageText);
  }

  // Handle user state changes in case of Logout
  /* istanbul ignore next */
  async function onAuthStateChanged(userFromFirebase) {
    // console.log('Se está cambiando la sesión ------------------------');
    console.log('//??', userFromFirebase);
    if (userFromFirebase) {
      // save in async storage not modify the context
      await AsyncStorage.setItem(
        'user',
        JSON.stringify({userFB: userFromFirebase}),
      );
      if (step === screens.CODE && lwc) {
        if (isRecovery) {
          await handleRecoveryCode(null, type, true);
        } else {
          await handleVerificationCode(null, type, true);
        }
      }
    }
  }

  /** SignIn con numero de telefono */

  /* istanbul ignore next */
  async function signInWithCellphone(phoneNumber) {
    try {
      const {number} = phoneNumber;
      signInWithPhoneNumber(number)
        .then(async confirmCode => {
          await setConfirm(confirmCode);
          await setStep(screens.CODE);
        })
        .catch(async e => {
          console.log(e);
          // ¿Cómo sabemos si el error es por un número inválido?
          reportError(
            ROUTE_NAMES.login,
            {extra: {phoneNumber}},
            'signIn',
            e,
            t('error:invalidNumber'),
          );
          handleMessageModal(true, true, t('error:invalidNumber'));
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.log(e);
      // ¿Cómo sabemos si el error es por un número inválido?
      reportError(
        ROUTE_NAMES.login,
        {extra: {phoneNumber}},
        'signIn',
        e,
        t('error:invalidNumber'),
      );
      handleMessageModal(true, true, t('error:invalidNumber'));
      setLoading(false);
    }
  }

  /** Obtener codigo por whatts  */

  /* istanbul ignore next */
  async function getCodeMessageByCallOrWa(type) {
    try {
      setLoading(true);
      await requestCall(phone, callCode, type, i18n.language);
      await logEvent('CodeWhatsapp', {
        phone,
      });
      setLoading(false);
    } catch (e) {
      // ¿Cómo sabemos si el error es por código inválido?
      handleMessageModal(true, true, t('error:codeError'));
      reportError(
        ROUTE_NAMES.login,
        {extra: {phone, type}},
        'getCodeMessageByCallOrWa',
        e,
        t('error:codeError'),
      );

      setLoading(false);
    }
  }

  /* istanbul ignore next */
  async function checkValidEnterprise(companyAccess, policeNumber) {
    if (!companyAccess) {
      return true;
    }
    try {
      const isValid = await checkPolicy(policeNumber); //policeNumber 5d26959ebb
      console.log('>>> POLIZA: ', isValid);
      if (!isValid) {
        setLoading(false);
        handleMessageModal(true, true, t('error:enterpriseCode'));
        // llenar los campos de nuevo
        return false;
      }
      setEnterpriseCode(policeNumber);
      return true;
    } catch (e) {
      console.log(e);
      // Lanzamos el error que venga del catch
      reportError(
        ROUTE_NAMES.login,
        {extra: {companyAccess, policeNumber}},
        'checkValidEnterprise',
        e,
        t('error:enterpriseCode'),
      );
      handleMessageModal(true, true, e.message);
      return false;
    }
  }

  /* istanbul ignore next */
  async function handleRegister({
    type,
    companyAccess,
    phoneNumber,
    email,
    policeNumber,
    password,
  }) {
    setLoading(true);
    let validPolice = true;
    setIsRecovery(false);
    setType(type);
    setLwc(type === LOGIN_TYPES.REGISTER_PHONE);
    setIsB2B(companyAccess);
    setDefaultData({phone: phoneNumber, type, email, password});

    logMainScreen('intentLogin', pushState, {
      type: type,
      email,
      phoneNumber,
    });
    /** 1. Si es REGISTER_PHONE se usa isRegisterAndHavePassword */
    try {
      switch (type) {
        case LOGIN_TYPES.REGISTER_PHONE:
          const response = await isRegisterAndHavePassword(phoneNumber.number);

          const {isLogin, hasPassword, appVersion} = response;
          // TODO: agregar validación para usuarios con v1 sin pasword
          console.log('response');
          //console.log(JSON.stringify(response));

          if (isLogin && !(!hasPassword && appVersion === 1)) {
            setDefaultData({phone: phoneNumber});
            setStep(screens.LOGIN);
            setLoading(false);
            setShowModalLogin(true);
            handleMessageModal(true, true, t('error:alreadyRegister'));
            return;
          }
          /** 2.  Validar si es company access y si es valido el policeNumber o el emailCorporate */
          validPolice = await checkValidEnterprise(companyAccess, policeNumber);
          if (!validPolice) {
            return;
          }
          /** 3. Hacer login con FB con cellphone */
          setPhone(phoneNumber);
          setPassword(password);

          if (!hasPassword && appVersion === 1) {
            setIsUserFromV1(true);
            setPasswordFromV1(password);
            setIsRecovery(true);
            // changeStep(screens.CODE);
            await handleRecoveryPW({
              phoneNumber: phoneNumber,
              type: LOGIN_TYPES.RECOVERY_PHONE,
              copy: t('login:codeSMSLabel'),
            });
            //await onSuccessful(type);
            break;
          }

          await signInWithCellphone(phoneNumber);
          return;
        case LOGIN_TYPES.REGISTER_EMAIL:
          validPolice = await checkValidEnterprise(companyAccess, policeNumber);
          if (!validPolice) {
            return;
          }

          //Validar si el email ya está en BD enviando la verificación.
          //Si regresa BAD_CREDENCIALS SIGNIFICA QUE Ya está logueador
          const emailType = await sendVerificationEmail(
            email,
            type,
            i18n.language,
          );
          await setEmail(email);
          setPassword(password);
          if (emailType.includes('LOGIN')) {
            setDefaultData({email});
            setStep(screens.LOGIN);
            setLoading(false);
            handleMessageModal(true, true, t('error:noRegister'));
            return;
          }
          setShowModalLogin(true);
          await setStep(screens.CODE);
          setLoading(false);
          return;
      }
    } catch (e) {
      setLoading(false);
      console.log('Error: ', e);
      handleMessageModal(true, true, t('error:errorBack'));
      reportError(
        ROUTE_NAMES.login,
        {extra: {type, companyAccess, phoneNumber, email, policeNumber}},
        'handleRegister',
        e,
        t('error:errorBack'),
      );
    }
  }

  async function handleExternalAuth({customToken}) {
    try {
      let typeLogin = LOGIN_TYPES.LOGIN_EXTERNAL;
      setLoading(true);
      setType(typeLogin);
      await signInWithCustomToken(customToken);
      await onSuccessful(typeLogin, {customToken});
    } catch (e) {
      const errorMsg = t(e.message);
      reportError('login', {customToken}, 'handleExternalAuth', e, errorMsg);
      handleMessageModal(true, true, t(e?.message));
    } finally {
      setLoading(false);
    }
  }

  async function handleLoginEmployeeNumber({employeeNumber, employeeName}) {
    try {
      setLoading(true);
      let typeLogin = LOGIN_TYPES.LOGIN_EMPLOYEE_NUMBER;
      setType(typeLogin);
      // TODO: validar con Ale o Fer los logs de login
      // await logMainScreen('intentLogin', pushState, {
      //   typeLogin,
      //   employeeNumber,
      //   employeeName,
      // });
      const tokenBack = await authSingleEmployeeLogin({
        employeeNumber,
        fullName: employeeName,
        prefix: registerPrefix,
      });
      console.log('tokenBack: ', tokenBack);
      await signInWithCustomToken(tokenBack);
      console.log('login con employee number: ', employeeNumber);
      console.log('login con employee name: ', employeeName);
      await onSuccessful(typeLogin, {employeeNumber});
    } catch (e) {
      setLoading(false);
      const mensaje = t(e.message);
      reportError('cms', {employeeNumber}, 'CMS', e, mensaje);
      handleMessageModal(true, true, t(e?.message));
    }
  }

  /* istanbul ignore next */
  async function handleLogin({email, password, phoneNumber, type}) {
    try {
      setLoading(true);
      setLwc(type === LOGIN_TYPES.LOGIN_PHONE);
      setType(type);
      await logMainScreen('intentLogin', pushState, {
        type: type,
        email,
        phoneNumber,
      });
      switch (type) {
        case LOGIN_TYPES.LOGIN_PHONE:
          const response = await isRegisterAndHavePassword(phoneNumber.number);
          const {isLogin, hasPassword, appVersion} = response;
          if (!isLogin) {
            setStep(screens.REGISTER);
            setDefaultData({phone: phoneNumber});
            setLoading(false);
            handleMessageModal(true, true, t('error:noRegister'));
            return;
          }

          if (!hasPassword && appVersion === 1) {
            setDefaultData({phone: phoneNumber});
            setStep(screens.REGISTER);
            setLoading(false);
            handleMessageModal(true, true, t('error:noRegister'));
            setIsUserFromV1(true);
            setPasswordFromV1(password);
            break;
          }
          setPhone(phoneNumber);
          const tokenBack = await loginWithPhoneAndPassword(
            phoneNumber.number,
            password,
          );
          await signInWithCustomToken(tokenBack);
          await onSuccessful(type, {phone: phoneNumber});
          break;
        case LOGIN_TYPES.LOGIN_EMAIL:
          await signInWithEmailAndPassword(email, password);
          await onSuccessful(type, {email});
          break;
      }
    } catch (e) {
      setLoading(false);
      console.log('Error: ', e, t(e.message));
      handleMessageModal(true, true, t(e.message));
      reportError(
        ROUTE_NAMES.login,
        {extra: {email, phoneNumber, type}},
        'handleLogin',
        e,
        t(e.message),
      );
    }
  }

  /** Confirmar el codigo de verificcion de celular */

  /* istanbul ignore next */
  async function handleVerificationCode(code, automatic = false) {
    try {
      setLoading(true);
      // await logMainScreen('intentLogin', pushState, {type: 'RECOVERY'});
      if (lwc) {
        if (!automatic) {
          //  Probar primero el checkCallCenterCode   636900
          const response = await checkCallCenterCode(phone, callCode, code);
          if (response.status !== 'LOGGED') {
            // Confirmamos que el código sea correcto en FB
            await setConfirmCode(confirm, code);
          } else {
            // si se logueó con codigo de llamada o whats.
            await signInWithCustomToken(response.token);
          }
        }
        //creamos el usuario
        await createUser(
          isB2B,
          enterpriseCode,
          false,
          false,
          user?.userFB?.uid,
        );
        // Si todo sale bien, aguardamos la contraseña
        await savePassword(password);
        //Si todo sale bien vamos a onboarding
        await onSuccessful(type, {phone});
      } else {
        //Verificamos el codigo sea correcto
        await verifyEmailCode(email, code);
        // Creamos el usuario en FB
        const a = await createUserByEmailAndPassword(email, password);
        // Creamos el usuario en nuestra BD
        const uid = user?.userFB?.uid || a?.user?.uid;
        const validUser = await createUser(
          isB2B,
          enterpriseCode,
          code,
          false,
          uid,
        );

        if (validUser?.status === 'UNAUTHORIZED_B2B') {
          handleMessageModal(true, true, t('login:errorB2B'));
          setStep('REGISTER');
          setLoading(false);
          return;
        }

        //Si todo sale bien vamos a onboarding
        await onSuccessful(type, {email});
      }
    } catch (e) {
      setLoading(false);
      reportError(
        ROUTE_NAMES.login,
        {extra: {code, email}},
        'handleVerificationCode',
        e,
        t(e?.message),
      );
      handleMessageModal(true, true, t(e?.message));
    }
  }

  async function handleRequestEmailCode(email, type, lang) {
    setLoading(true);
    try {
      await sendVerificationEmail(email, type, i18n.language);
      await logEvent('CodeMail', {
        email,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      reportError(
        ROUTE_NAMES.login,
        {extra: {email, type, lang}},
        'handleRequestEmailCode',
        e,
        `Error al pedir codigo de verificación ${email}`,
      );
    }
  }

  /* istanbul ignore next */
  function handleForgotPassword(params) {
    setDefaultData({
      email: params.email,
      phone: params.phoneNumber,
      lwc: params.lwc,
    });
    setStep(screens.PWRECOVERY);
  }

  /* istanbul ignore next */
  async function handleRecoveryPW(params) {
    setLoading(true);
    const {type, email, phoneNumber, copy} = params;
    setType(type);
    setEmail(email);
    setPhone(phoneNumber);

    // await logMainScreen('intentLogin', pushState, {type});

    const {RECOVERY_PHONE} = LOGIN_TYPES;
    const infoMessage = copy
      ? copy
      : type === RECOVERY_PHONE
      ? t('login:smsRecovery')
      : t('login:emailRecovery');
    /**
     * Mostrar el olvido contraseña
     * Si es celular: entonces:
     *  manda codigo de verificacion
     * cuando es correcto lleva a PW:
     * Si no pues el sendForgot
     */

    const sendRecovery = async () => {
      if (type === RECOVERY_PHONE) {
        setPhone(phoneNumber);
        return signInWithCellphone(phoneNumber).then(_ => {
          setIsRecovery(true);
          setShowModalLogin(true);
          changeStep(screens.CODE);
        });
      }
      // If is email
      try {
        // 1. Mandar el correo con un codigo usando: sendVerificationEmail
        const send = await sendVerificationEmail(email, type, i18n.language);
        setLwc(false);
        setShowModalLogin(true);
        setDefaultData({email});

        setStep(screens.CODE);
        setIsRecovery(true);
      } catch (e) {
        console.log(e);
        // ¿Por qué se relanza el error?
        // reportError(
        //   'Login',
        //   {extra: {email, phoneNumber, type}},
        //   'handleRecoveryPW',
        //   e,
        // );
        throw e;
      }
    };
    sendRecovery()
      .then(async _ => {
        await logEvent('PasswordRecovery', {
          type,
        });
      })
      .catch(async e => {
        console.log('un error asi: ', e);
        if (e.code === 'auth/user-not-found') {
          console.log('Enviar a crear cuenta');
          setDefaultData({email});
          setStep(screens.REGISTER);
          setLoading(false);
        } else {
          const errorMessage =
            type === RECOVERY_PHONE
              ? t('error:invalidNumber')
              : t('error:invalidEmail');
          reportError(
            ROUTE_NAMES.login,
            {extra: {email, phoneNumber, type}},
            'handleRecoveryPW',
            e,
            errorMessage,
          );
          handleMessageModal(true, true, errorMessage);
        }
        // return error to catch in parent function
        return e;
      })
      .finally(_ => {
        setLoading(false);
      });
  }

  /* istanbul ignore next */
  async function handleRecoveryCode(code, type, automatic = false) {
    try {
      setLoading(true);
      if (type === LOGIN_TYPES.RECOVERY_PHONE) {
        if (!automatic) {
          //  Probar primero el checkCallCenterCode   636900
          const response = await checkCallCenterCode(phone, callCode, code);
          if (response.status !== 'LOGGED') {
            // Confirmamos que el código sea correcto en FB
            await setConfirmCode(confirm, code);
          } else {
            // si se logueó con codigo de llamada o whats.
            await signInWithCustomToken(response.token);
          }
        }
        // validamos que el usuario no sea de la versión 1 y tenga que validar con código
        if (isUserFromV1) {
          await createUser(
            isB2B,
            enterpriseCode,
            code,
            false,
            user?.userFB?.uid,
          );
          await handleNewPassword(passwordFromV1);
        } else {
          changeStep(screens.NEWPASSWORD);
          setLoading(false);
        }
        return;
      }
      // Si es email
      //Verificamos el codigo sea correcto
      const token = await verifyEmailCode(email, code);
      if (!token) {
        // posiblemente no se registro y no tenemos a ese usuario.
        changeStep(screens.NEWPASSWORD);
        setUserNotFound(true);
        setCodeRecovery(code);
        throw new Error(t('error:noRegister'));
      }
      console.log('token', token);
      await signInWithCustomToken(token);
      changeStep(screens.NEWPASSWORD);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log('Error: ', e);
      reportError(
        ROUTE_NAMES.login,
        {extra: {code, type}},
        'handleRecoveryCode',
        e,
        t(e?.message),
      );

      if (!userNotFound) {
        handleMessageModal(true, true, t(e?.message));
      }
    }
  }

  /* istanbul ignore next */
  async function handleNewPassword(password) {
    setLoading(true);
    await logMainScreen('intentLogin', pushState, {type: 'NEW_PASSWORD'});

    try {
      if (type !== LOGIN_TYPES.RECOVERY_EMAIL) {
        await savePassword(password);
        await onSuccessful(type, {});
        setPasswordFromV1(null);
      }
      // is email
      /* caso donde el usuario no existe y lo mandamos directo a registrar y crear su cuenta sin pasar ya por la pantalla CODE */
      if (userNotFound) {
        const auth = await createUserByEmailAndPassword(email, password);
        // Creamos el usuario en nuestra BD
        const uid = user?.userFB?.uid || auth?.user?.uid;
        await createUser(isB2B, enterpriseCode, codeRecovery, false, uid);
        //Si todo sale bien vamos a onboarding
        await onSuccessful(type, {email});
        return;
      }
      await changeEmailPassword(password);
      await onSuccessful(type, {});
    } catch (e) {
      setLoading(false);
      console.log('Error: ', e);
      handleMessageModal(true, true, t(e?.message));
      reportError(
        ROUTE_NAMES.login,
        {extra: {password, email}},
        'handleNewPassword',
        e,
        t(e?.message),
      );
    }
  }

  // console.log('anonymousOnBoarding', token);

  async function eventForSuccess(loginType, loginData) {
    let onboarding = loginType.includes('REGISTER');
    const log = loginType.includes('REGISTER')
      ? 'SingupSuccesfull'
      : 'LoginSuccesful';
    await logEvent(log, {
      type: isB2B ? 'B2B' : 'B2C',
      specificType: loginType,
    });

    const loginInfo = {
      loginEvent: loginType,
      type: loginType.includes('REGISTER') ? 'register' : 'login',
      businessModel: isB2B ? 'B2B' : 'B2C',
      info: loginData,
    };

    await logMainScreen('login_success', pushState, {...loginInfo});

    await logRudderEvent({
      name: loginType.includes('REGISTER') ? 'register' : 'login',
      step: `${anonymousLoginStep}.Z Login`,
      extraProperties: {
        ...loginInfo,
      },
      pushState,
    });

    await logRudderEvent({
      name: 'anonymousLogin',
      step: `${anonymousLoginStep}.Z Login`,
      extraProperties: {
        type: loginType,
      },
      screen: from,
      pushState,
    });

    await logEvent(
      'success ' + loginType.includes('REGISTER') ? 'register' : 'login',
      {},
    );
  }

  /* istanbul ignore next */
  async function onSuccessful(loginType, loginData) {
    if (registerPrefix) {
      await AsyncStorage.setItem('registerPrefix', registerPrefix);
    }

    await refreshToken();
    await eventForSuccess(loginType, loginData);

    let onboarding = loginType.includes('REGISTER');
    const log = loginType.includes('REGISTER');

    const profileData = {
      language: isWeb() ? 'es' : i18n.language,
    };

    if (log !== 'LoginSuccesful') {
      profileData.extraAppData = JSON.stringify({
        hasCompletedOnboarding: false,
      });
    }

    const variablesIfIsLogin = {
      flow: pricingFlow,
      profile: profileData,
    };

    // Guardar pricingFlow en back y obtener bandera de nuevo usuario y flujo que tiene guardado si aplica
    // Aquí nos traemos la info del usuario que necesitamos.
    updatePricingFlow({
      variables: {...variablesIfIsLogin},
    })
      .then(async ({data}) => {
        const pricing =
          data.pricingFlow?.created && data.pricingFlow?.flow === 'C';

        onboarding = data.pricingFlow.created || isUserFromV1;
        setSession({
          authenticated: true,
          onboarding,
          pricing,
        });
      })
      .catch(e => {
        console.log('ERROR', e);
        reportError(
          'graphql',
          {},
          'UPDATE_PRICING_FLOW',
          e,
          'Error al UPDATE_PRICING_FLOW',
        );
        setLoading(false);
      })
      .finally(async () => {
        // change step to first screen & loading
        changeStep(screens.REGISTER);
        setLoading(false);

        // si viene de cuenta, manda a home
        if (from === ROUTE_NAMES.account) {
          resetNavigation();
          return;
        }

        if (onboarding) {
          const routeName = isWeb()
            ? ROUTE_NAMES.chat
            : ROUTE_NAMES.exerciseChat;
          // sin importar que sea web o no, porque en web checamos el CF desde cms.
          const conversation = anonymousOnBoarding?.firstConversation?.flowName;
          await navigateTo(
            routeName,
            {
              conversationFlow: conversation,
              title: anonymousOnBoarding?.firstConversation?.title,
              onBoarding: true,
            },
            !isWeb(),
          );
          return;
        }

        if (goTherapy) {
          navigateTo('TherapyDetails', {id});
          return;
        }

        // independientemente si es registro o login manda a home (alla checamos el onboarding con extraInfo)
        if (isWeb()) {
          resetNavigation();
          console.log('refreshing...');
          // Fix navigation after login
          setTimeout(() => {
            window.location.reload(); // Esto recargará la página completa
          }, 150);
          return;
        }
        // si es login y mobile
        resetNavigation();
      });
  }

  function changeStep(newStep) {
    setDefaultData(null);
    setStep(newStep);
  }

  // estilos para que los botones en web no se vean tan alejados + estilos de fuente
  const commonStylesWeb = {
    minW: isWeb() ? '50%' : '100%',
    _text: {
      fontSize: 'md',
      fontWeight: 800,
      fontFamily: isWeb() ? 'Quicksand' : 'quicksand',
    },
  };

  function resetNavigation() {
    navigation.reset({
      index: 0,
      routes: [{name: ROUTE_NAMES.home}],
    });
  }

  return (
    <BackgroundGradient type={'LOGIN'} padding={false}>
      {
        {
          REGISTER: (
            <Register
              setIsWarning={setIsWarning}
              setShowModalMessage={setShowModalMessage}
              setMessage={setMessage}
              onSubmit={handleRegister}
              onLogin={() => changeStep('LOGIN')}
              defaultData={defaultData}
              loading={loading}
              back={back}
              commonStylesWeb={commonStylesWeb}
            />
          ),
          LOGIN_EMPLOYEE_NUMBER: (
            <EntryEmployeeLogin
              onSubmit={handleLoginEmployeeNumber}
              onBack={() => changeStep('LOGIN_EMPLOYEE_NUMBER')}
              loading={loading}
              commonStylesWeb={commonStylesWeb}
            />
          ),
          LOGIN: (
            <EntryLogin
              onSubmit={handleLogin}
              onCreate={() => changeStep('REGISTER')}
              defaultData={defaultData}
              forgotPassword={handleForgotPassword}
              loading={loading}
              back={back}
              commonStylesWeb={commonStylesWeb}
            />
          ),
          CODE: (
            <Code
              lwc={lwc}
              showModalLogin={showModalLogin}
              onSubmit={handleVerificationCode}
              onBack={() => changeStep(isRecovery ? 'PWRECOVERY' : 'REGISTER')}
              onRequestCode={getCodeMessageByCallOrWa}
              isRecovery={isRecovery}
              onSubmitRecovery={handleRecoveryCode}
              onRequestEmailCode={handleRequestEmailCode}
              loading={loading}
              params={{email, phone, type}}
              commonStylesWeb={commonStylesWeb}
            />
          ),
          PWRECOVERY: (
            <PasswordRecovery
              defaultData={defaultData}
              onBack={() => changeStep('LOGIN')}
              onSubmit={handleRecoveryPW}
              loading={loading}
              showModalLogin={showModalLogin}
              commonStylesWeb={commonStylesWeb}
            />
          ),
          NEWPASSWORD: (
            <NewPassword
              onSubmit={handleNewPassword}
              onBack={() => changeStep('LOGIN')}
              loading={loading}
              commonStylesWeb={commonStylesWeb}
            />
          ),
        }[step]
      }
      <MessageSystem
        isWarning={isWarning}
        showModalMessage={showModalMessage}
        setShowModalMessage={setShowModalMessage}
        message={message}
      />
    </BackgroundGradient>
  );
}
