import React from 'react';
import {View, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import {colors} from '../../styles/colors';

const Center = ({
  children,
  alignItems,
  alignSelf,
  bg,
  borderRadius,
  color,
  flex,
  w,
  h,
  justifyContent,
  m,
  mb,
  ml,
  mr,
  mt,
  mx,
  my,
  p,
  pb,
  pl,
  pr,
  px,
  py,
  rounded,
  roundedBottom,
  shadow,
  textAlign,
  style,
  ...props
}) => {
  function getBorderRadiusValue(value) {
    if (value === 'full') {
      return 9999;
    }
    if (value === 'xl') {
      return 16;
    }
    if (value === 'lg') {
      return 12;
    }

    if (value === 'md') {
      return 8;
    }

    if (value === 'sm') {
      return 4;
    }

    return 0;
  }

  const centerStyle = [
    styles.center,
    bg && {backgroundColor: colors[bg?.replace('.', '')]},
    color && {color: colors[color?.replace('.', '')]},
    mr && {marginRight: mr},
    mx && {marginHorizontal: mx},
    my && {marginVertical: my},
    mt && {marginTop: mt},
    mb && {marginBottom: mb},
    ml && {marginLeft: ml},
    pr && {paddingRight: pr},
    px && {paddingHorizontal: px},
    py && {paddingVertical: py},
    pb && {paddingBottom: pb},
    pl && {paddingLeft: pl},
    rounded && {borderRadius: rounded},
    roundedBottom && {
      borderBottomLeftRadius: roundedBottom,
      borderBottomRightRadius: roundedBottom,
    },
    borderRadius && {
      borderRadius:
        typeof borderRadius === 'number'
          ? borderRadius
          : getBorderRadiusValue(borderRadius),
    },
    shadow && {shadowColor: shadow},
    textAlign && {textAlign: textAlign},
    alignItems && {alignItems: alignItems},
    justifyContent && {justifyContent: justifyContent},
    m && {margin: m},
    p && {padding: p},
    w && {width: w},
    h && {height: h},
    flex && {flex: flex},
    alignSelf && {alignSelf: alignSelf},
    style,
  ];

  return (
    <View style={centerStyle} {...props}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

Center.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  mr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mx: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  my: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pr: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  px: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  py: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rounded: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  roundedBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shadow: PropTypes.string,
  textAlign: PropTypes.string,
  alignItems: PropTypes.string,
  alignSelf: PropTypes.string,
  justifyContent: PropTypes.string,
  m: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  p: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  w: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flex: PropTypes.number,
  style: PropTypes.object,
};

export default Center;
