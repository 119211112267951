import {useMutation, useQuery} from '@apollo/client';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  Box,
  Button,
  Center,
  Modal,
  ScrollView,
  Text,
  VStack,
} from 'native-base';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CHAT_MODULE, CHAT_TYPES} from '../../constants/codeConstants';
import {
  FeatureFlagsContext,
  PushStateContext,
  RemoteConfigContext,
  UserContext,
} from '../../hooks/SessionContext';
import NavigateBar from '../../components/NavigateBar';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';
import ChatMainStreaming from './ChatMainStreaming';
import {CREATE_DIARY_ENTRY} from '../../graphql/queries/diary';
import {
  logRudderEvent,
  logSimpleEvent,
} from '../../helpers/analytics/fbAnalytics';
import {ROUTE_NAMES, navigateTo} from '../../navigation/MainNavigator';
import usePrivileges from '../../hooks/useUserSubscriptionAccess';
import QuestionChips from '../../components/Chat/QuestionChips';
import {GET_DISPLAY_DETAILS_GROUPS_CHIPS} from '../../graphql/queries/home';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';

/**
 * Componente Screen ChatStreamingText
 */

export default function ChatStreamingText({}) {
  const navigation = useNavigation();
  const route = useRoute();
  const {
    showChips = true,
    conversationFlow = null,
    titleCF,
    isExercise,
  } = route?.params || {};
  const {t, i18n} = useTranslation();
  const insets = useSafeAreaInsets();
  const [msges, setMsges] = useState([]);
  const [chips, setChips] = useState([]);
  const [showChipsQuestion, setShowChipsQuestion] = useState(showChips);
  const {pushState} = useContext(PushStateContext);
  const [showModal, setShowModal] = useState(false);
  const [finishChat, setFinishChat] = useState(false);
  const [conversationSelected, setConversationSelected] =
    useState(conversationFlow);
  const {width} = getWidthAndHeight();
  const {user} = useContext(UserContext);
  const {identifyRudderStack} = usePrivileges();
  const chatMainStreamingRef = useRef();
  const {
    remoteConfig: {gpt3Diary},
  } = useContext(RemoteConfigContext);
  const {featureFlags} = useContext(FeatureFlagsContext);
  const [companyVariables, setCompanyVariables] = useState(); // para probar el flujo de red sin mandar a tiendas

  const language = i18n?.language;
  const gpt3Labels = {
    title: gpt3Diary?.title?.[language] || '',
    titleHeader: gpt3Diary?.titleHeader?.[language] || '',
    firstMessageShowedInHome:
      gpt3Diary?.firstMessageShowedInHome?.[language] || '',
    explanationTitle: gpt3Diary?.explanation?.title?.[language] || '',
    explanationDescription:
      gpt3Diary?.explanation?.description?.[language] || '',
  };
  const [title, setTitle] = useState(titleCF || gpt3Labels?.titleHeader);

  const {data: dataTrends} = useQuery(GET_DISPLAY_DETAILS_GROUPS_CHIPS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: error => {
      reportError('GET_DISPLAY_DETAILS_GROUPS_CHIPS Chat', error, 'useQuery');
    },
  });

  useEffect(() => {
    if (user) {
      const companyVariablesCMS = user?.userCMS?.workCompany?.variables || {};
      setCompanyVariables(companyVariablesCMS);

      identifyRudderStack();
    }

    if (dataTrends?.displayDetailGroups?.edges) {
      const edges = dataTrends?.displayDetailGroups?.edges;
      setChips(edges?.[0]?.node?.displayDetails);
    }
  }, [user, dataTrends]);

  // Confirmar con Ale: duda vamos a seguir usando esta mutacion en este chat?
  const [createDiaryEntry] = useMutation(CREATE_DIARY_ENTRY, {
    onError: callbackError,
    onCompleted: callBackSuccess,
  });

  function openMenu() {
    setShowModal(true);
  }

  useFocusEffect(
    React.useCallback(() => {
      AsyncStorage.getItem('showAboutModal').then(value => {
        if (value === null) {
          setShowModal(true);
        }
      });
      // Do something when the screen is focused
      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
      };
    }, [msges]),
  );

  function onSaveAbout() {
    AsyncStorage.setItem('showAboutModal', 'false');
    setShowModal(false);
  }

  function callbackError(e) {
    console.log('ERROR', e);
    onBack();
  }

  // esta funcion la usaremos tanto si se sale o si se termina la conversacion completa
  async function callBackSuccess(data) {
    chatMainStreamingRef?.current?.finishConversation();
    const params = {
      conversationFlow:
        conversationSelected === null
          ? gpt3Diary?.conversationFlowName
          : conversationSelected,
      conversation: msges,
      sessionID: msges[0]?.extraData?.session_id || null,
      messageID: msges[0]?.extraData?.message_id || null,
    };

    await logRudderEvent({
      name: 'conversationFlow',
      step: '4.B Exit',
      screen: 'Home',
      extraProperties: {
        to: {
          screen: 'Home',
        },
        pushState,
        ...params,
      },
    });
    await logSimpleEvent('asistente_end');

    onBack();
  }

  function onBack() {
    const insightBanner = companyVariables?.insights_banner === 'true';
    if (insightBanner && finishChat) {
      navigation.navigate(ROUTE_NAMES?.myInsights, {isFinishStream: true});
      return;
    }

    if (isWeb()) {
      navigateTo(ROUTE_NAMES.home);
      return;
    }
    navigation.goBack();
  }

  async function finishExercise() {
    setFinishChat(true);
    await createDiaryEntry({
      variables: {
        input: {
          conversationFlowName: gpt3Diary?.conversationFlowName,
          conversation: JSON.stringify(msges),
        },
      },
    });
  }

  async function onSelectChip(item) {
    console.log('chip seleccionado', item);
    setConversationSelected(item?.conversationFlow?.name);
    setShowChipsQuestion(false);
    setTitle(item?.title);

    await logRudderEvent({
      name: 'conversationFlow',
      step: 'O.C SelectChipAssistant',
      screen: 'ChatGenerico',
      extraProperties: {
        pushState,
        content: item?.title,
        conversationFlow: item?.conversationFlow.name,
        messageType: 'text',
      },
    });
  }

  const ModalAI = ({}) => (
    <Modal
      testID="modal-ai"
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      _backdrop={{opacity: 0.65}}>
      <Modal.Content bg={'secondary.100'} borderColor={'primary.5'}>
        <Modal.CloseButton
          _icon={{color: 'primary.100'}}
          _pressed={{bg: 'transparent'}}
          testID="close-emotion-desc"
        />
        <Modal.Body mt={4} p={5}>
          <VStack>
            <Text
              textAlign={'center'}
              color={'primary.100'}
              fontSize={'xl'}
              fontWeight={800}>
              {gpt3Labels?.explanationTitle}
            </Text>
            <Text
              mt={3}
              textAlign={'center'}
              color={'primary.100'}
              fontSize={'xl'}>
              {t('chat:aiAboutSubTitle')}
            </Text>
            <Text
              fontSize={'md'}
              textAlign={'center'}
              color={'primary.100'}
              mt={3}>
              {gpt3Labels?.explanationDescription}
            </Text>
            <Center mt={3}>
              <Button onPress={onSaveAbout} px={10} variant={'primary'}>
                {t('chat:aiAboutButton')}
              </Button>
            </Center>
          </VStack>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );

  return (
    <Box
      h={'100%'}
      flex={1}
      pt={insets.top === 0 ? 5 : Math.min(insets.top, 10)}>
      <Box h={title?.length > 25 && !isWeb() ? '9%' : '5%'} w={width}>
        <NavigateBar
          backEvent={async () => {
            await callBackSuccess();
          }}
          headerTitle={title}
          optionEvent={openMenu}
        />
      </Box>
      <Box h={'95%'} bg={'secondary.100'} borderTopRadius={45} py={3}>
        {showChipsQuestion &&
          companyVariables?.show_chips_assistant === 'true' && (
            <Box
              testID="chips-streaming"
              px={2}
              flexDirection={'row'}
              flexWrap={'wrap'}
              w={'100%'}>
              <ScrollView horizontal showsHorizontalScrollIndicator={false}>
                <Box flexDirection={'column'} justifyContent={'space-between'}>
                  {[0, 1, 2].map(rowIndex => (
                    <Box key={rowIndex} flexDirection={'row'} mb={2}>
                      {chips
                        ?.slice(
                          rowIndex * Math.ceil(chips?.length / 3),
                          (rowIndex + 1) * Math.ceil(chips?.length / 3),
                        )
                        ?.map((item, index) => (
                          <QuestionChips
                            key={`${rowIndex}-${index}`}
                            question={item}
                            onSelectChip={onSelectChip}
                          />
                        ))}
                    </Box>
                  ))}
                </Box>
              </ScrollView>
            </Box>
          )}
        <ChatMainStreaming
          ref={chatMainStreamingRef}
          chatModule={CHAT_MODULE.GPT}
          onFinishConversation={finishExercise}
          type={conversationFlow ? CHAT_TYPES.ACTION : CHAT_TYPES.TEXT}
          conversationFlowName={conversationSelected}
          setShowChipsQuestion={setShowChipsQuestion}
          showChipsQuestion={showChipsQuestion}
          isExercise={isExercise}
          title={title}
        />
        <ModalAI />
        <Box h={Math.min(insets.bottom, 14)} bg={'secondary.100'} />
      </Box>
    </Box>
  );
}
