// Import the functions you need from the SDKs you need
//import firebase, {initializeApp} from 'firebase/app';
// Importa la biblioteca de Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
//import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/remote-config';
import {getMessaging, getToken} from 'firebase/messaging';
import {getAuth, onAuthStateChanged} from 'firebase/auth';

import {firebaseConfig} from '../../web.config';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
// Inicializa Firebase
firebase.initializeApp(firebaseConfig);
export const initFirebase = () => {
  //console.log('here init fb web', firebaseConfig);
  firebase.initializeApp(firebaseConfig);
  const auth = getAuth();
  onAuthStateChanged(auth, user => {
    if (user) {
      console.log(`User signed in: ${user.uid}`);
      return user;
    } else {
      console.log(`No signed in users, trying to sign in anonymously...`);
      return null;
    }
  });
};

export const fbFunctions = firebase.functions();
export const database = firebase.database();
export const remoteConfigInstance = firebase.remoteConfig();
export const messaging = {
  getToken,
  getMessaging,
};
export const analytics = firebase.analytics();

export const recaptcha = () => {
  const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    'register-button-id',
    {
      size: 'invisible',
      callback: function (response) {
        console.log('It works!');
      },
    },
  );
  reCaptchaVerifier.render();
  return reCaptchaVerifier;
};

let recap = null;

// Utiliza los servicios de Firebase

/** Auth with Phone*/
export const signInWithPhoneNumber = async (phoneNumber, reCaptchaVerifier) => {
  // let recap = reCaptchaVerifier;
  // if (!recap) {
  //   recap = new firebase.auth.RecaptchaVerifier('register-button-id', {
  //     size: 'invisible',
  //     callback: function (response) {
  //       console.log('It works!');
  //     },
  //   });
  //   recap.render();
  // }
  console.log('>>>>>🐞🐞🐞🐞:::Anres  recaptcha-register-button-id');

  if (!recap) {
    recap = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
      callback: function (response) {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('>>>>>🐞🐞🐞🐞:::Anres  recaptcha-register-button-id');
        //onSignInSubmit();
      },
    });
  }

  console.log('>>>>>🐞🐞🐞🐞:::', recap);

  return firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, recap)
    .then(confirmation => {
      console.log('la confirmacion', confirmation);
      return confirmation;
    })
    .catch(e => {
      console.log('>>', e);
      reportError('fb', {phoneNumber}, 'signInWithPhoneNumber', e);
      // TODO exception: adjuntar el error original y validar el tipo de error
      throw new Error('error:generic');
    });
};

/** Auth with Email and Password */
export const signInWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (e) {
    console.log(e);
    reportError('fb', {email}, 'signInWithEmailAndPassword', e);
    // TODO exception: adjuntar el error original y validar el tipo de error
    throw new Error('error:loginInvalid');
  }
};

/** Auth with CustomToken  todo: checar*/
export const signInWithCustomToken = async token => {
  try {
    await firebase.auth().signInWithCustomToken(token);
  } catch (e) {
    console.log(e);
    reportError('fb', {token}, 'signInWithCustomToken', e);
    // TODO exception: adjuntar el error original y validar el tipo de error
    throw new Error('error:loginInvalid');
  }
};

/** Create User */
export const createUserByEmailAndPassword = async (email, password) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(res => res)
    .catch(e => {
      console.log(e);
      reportError('fb', {email}, 'createUserByEmailAndPassword', e);
      // TODO exception: adjuntar el error original y validar el tipo de error
      throw new Error('error:signinEmail');
    });
};

/** Verification Code todo: checar*/
export const setConfirmCode = async (confirm, code) => {
  try {
    if (!confirm) throw new Error('error:invalidCode');
    await confirm?.confirm(code);
  } catch (e) {
    console.log(e);
    reportError('fb', {code}, 'setConfirmCode', e);
    throw new Error('error:invalidCode');
  }
};

/** Anonymous Auth */
export const signInAnonymous = async () => {
  return firebase
    .auth()
    .signInAnonymously()
    .then(anonymous => {
      console.log('yei anonmoooo', anonymous);
      return anonymous;
    })
    .catch(e => {
      reportError('fb', {}, 'signInAnonymous', e);
      console.log('errrorrrr', e);
    });
};

/** SingOut */
export const singOut = () => {
  try {
    return firebase
      .auth()
      .signOut()
      .then(e => {
        console.log(e);
      })
      .catch(err => {
        reportError('fb', {}, 'singOut', err);
        throw new Error(err);
      });
  } catch (e) {
    throw new Error(e);
  }
};

/** Check if auth session changed */
export const onSessionChanged = callback => {
  return firebase.auth().onAuthStateChanged(callback);
};

/** Forgot Password */
export const sendForgotPassword = email => {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(res => res)
    .catch(e => {
      console.log(e);
      //reportError('fb', {email}, 'sendForgotPassword', e);
      throw e;
    });
};

export const remoteConfigFetch = async () => {
  return firebase
    .remoteConfig()
    .fetch(0)
    .then(fetchedRemotely => {
      console.log('>>', fetchedRemotely);
      return remoteConfigInstance.getValue('pricingFlow').asString();
    });
};

export const checkToken = async callback => {
  try {
    // Intentar obtener el usuario actual
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      // Si existe un usuario actual, obtener su token
      const idToken = await currentUser.getIdToken();
      console.log('Token activo: ', idToken);
      callback(idToken);
      return;
    }

    // Si no hay un usuario actual, configurar el listener onAuthStateChanged
    console.log('No se encontró un usuario actual, configurando listener.');
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        try {
          const idToken = await user.getIdToken();
          console.log('Token activo desde listener: ', idToken);
          callback(idToken);
        } catch (error) {
          console.error('Error al obtener el token en el listener: ', error);
          reportError('fb', {}, 'checkToken', error);
          callback(null); // Notificar que no se pudo obtener el token
        }
      } else {
        console.warn('No hay un usuario autenticado.');
        callback(null); // Notificar que no hay usuario
      }

      // Desuscribirse del listener una vez que se ejecuta
      unsubscribe();
    });
  } catch (error) {
    // Manejo de errores generales
    console.error('Error general en checkToken: ', error);
    reportError('fb', {}, 'checkToken', error);
    callback(null); // Notificar fallo
  }
};
