/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

// birthDate
export const GET_USER_DATA = gql`
  {
    profile {
      apellidoPaterno
      birthDate
      country
      dateJoined
      declaredCountry
      email
      extraAppData
      extraInfo
      gender
      hasChildren
      horarioContacto
      id
      language
      nombres
      notificationsEnabled
      occupation
      registrationPhone
      salaryIndicator
      shareCode
      timeZoneApp
      typeUser
      uuid
      forceProfiling
      workCompany {
        businessModel
        name
        publicId
        variables
      }
      workDep
    }

    profileContacts {
      id
      name
      phone
    }
  }
`;

// GET COMPANY VARIABLES
export const GET_COMPANY_VARIABLES = gql`
  {
    profile {
      workCompany {
        variables
        publicId
      }
    }
  }
`;

// GET EXTRA APP DATA
export const GET_EXTRA_APP_USER_DATA = gql`
  {
    profile {
      email
      extraAppData
    }
  }
`;

export const GET_VARIABLES_COMPANY = gql`
  {
    profile {
      nombres
      workCompany {
        variables
        publicId
      }
    }
  }
`;

export const GET_USER_BASIC_INFO = gql`
  {
    profile {
      email
      nombres
      registrationPhone
      timeZoneApp
    }
    appointmentsAvailable
  }
`;

export const UPDATE_PROFILING_WITH_CONTACT = gql`
  mutation updateProfileInfo(
    $profile: ProfileInput!
    $input: ContactMutationInput!
  ) {
    updateProfile(profile: $profile) {
      profile {
        nombres
        birthDate
        timeZoneApp
        country
        gender
        hasChildren
        occupation
        salaryIndicator
        workDep
      }
    }

    profileContact(input: $input) {
      contact {
        phone
        name
      }
    }
  }
`;

export const UPDATE_PROFILING = gql`
  mutation updateProfileInfo($profile: ProfileInput!) {
    updateProfile(profile: $profile) {
      profile {
        nombres
        notificationsEnabled
        extraAppData
      }
    }
  }
`;

export const UPDATE_PROFILING_WITH_CONTACTS = gql`
  mutation updateProfileInfo(
    $profile: ProfileInput!
    $input1: ContactMutationInput!
    $input2: ContactMutationInput!
  ) {
    updateProfile(profile: $profile) {
      profile {
        nombres
        birthDate
        timeZoneApp
        country
        gender
        hasChildren
        occupation
        salaryIndicator
        workDep
      }
    }

    profileContact1: profileContact(input: $input1) {
      contact {
        phone
        name
      }
    }

    profileContact2: profileContact(input: $input2) {
      contact {
        phone
        name
      }
    }
  }
`;

export const UPDATE_DEVICE_INFO = gql`
  mutation profileDeviceInfo(
    $input: DeviceInfoMutationInput!
    $profile: ProfileInput!
  ) {
    profileDeviceInfo(input: $input) {
      deviceInfo {
        id
        uniqueId
      }
      errors {
        field
        messages
      }
    }
    updateProfile(profile: $profile) {
      profile {
        id
        uuid
        nombres
        workCompany {
          publicId
          name
          businessModel
        }
        dateJoined
        extraInfo
      }
    }
  }
`;

export const UPDATE_PRICING_FLOW = gql`
  mutation pricingFlow($flow: String!, $profile: ProfileInput!) {
    pricingFlow(flow: $flow) {
      flow
      created
    }

    updateProfile(profile: $profile) {
      profile {
        nombres
        language
        extraAppData
        id
        uuid
        nombres
        workCompany {
          publicId
          name
          businessModel
        }
        dateJoined
        extraInfo
      }
    }
  }
`;

export const USER_FILE_STRING = `
{
  "query": "${`
    mutation userFile ($file: Upload!) {
    userFile (
      file: $file
      contentType: \\"IMAGE_TYPE_REPLACE\\"
    ) {
      userFile {
        id
        file
        path
        createdAt
        updatedAt
      }
    }
  }`.replace(/\n/g, ' ')}",
  "variables": {
    "file": null,
    "type": null
  },
  "operationName": "userFile"
}`;

/** Obtener los user files por id de file  */
export const GET_FILE = gql`
  query userFile($id: ID!) {
    userFile(id: $id) {
      id
      signedUrl
    }
  }
`;
