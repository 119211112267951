import React from 'react';
import {Text as RNText, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';

const Text = ({children, size, bg, color, style, ...props}) => {
  const textStyle = [
    styles.text,
    size && {fontSize: size},
    bg && {backgroundColor: bg},
    color && {color: color},
    style,
  ];

  return (
    <RNText style={textStyle} {...props}>
      {children}
    </RNText>
  );
};

const styles = StyleSheet.create({
  text: {
    fontFamily: 'System',
  },
});

Text.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.number,
  bg: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Text;
