import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ChatContainer from '../../components/Chat/ChatContainer/ChatContainer';
import ChatSkeletonStreaming from '../../components/Chat/ChatSkeleton/ChatSkeletonStreaming';
import useSocket from '../../hooks/useSocket';
import {isModuleActive, MODULES} from '../../core/featureFlagService';
import {
  FeatureFlagsContext,
  PushStateContext,
  RemoteConfigContext,
} from '../../hooks/SessionContext';
import {
  CHAT_MODULE,
  CHAT_STATE_RESPONSE,
  CHAT_TYPES,
  STREAMING_STATUS,
} from '../../constants/codeConstants';
import {
  logRudderEvent,
  logSimpleEvent,
} from '../../helpers/analytics/fbAnalytics';
import {useNavigation} from '@react-navigation/native';
import ModalFeedback from '../../components/ModalFeedback';
import ModalThanks from '../../components/ModalThanks';
import {storageFeedbackMessage} from '../../helpers/chatUtils';
import * as Sentry from '@sentry/react-native';
import {useTranslation} from 'react-i18next';
import {ROUTE_NAMES} from '../../navigation/MainNavigator';

const messagesTest = [
  {
    id: 0,
    content: '',
    isStreaming: true,
    bot: true,
  },
];

const ChatMainStreaming = forwardRef(
  (
    {
      conversationFlow = '',
      chatModule,
      onFinishConversation = () => {},
      type = 'text',
      conversationFlowName = null,
      setShowChipsQuestion,
      showChipsQuestion,
      isExercise = false,
      title,
    },
    ref,
  ) => {
    const navigation = useNavigation();
    const {t} = useTranslation();

    const [chatStateResponse, setChatStateResponse] = useState(
      CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE,
    );
    const [streamingStatus, setStreamingStatus] = useState(
      STREAMING_STATUS.DISCONNECTED,
    );
    const [messages, setMessages] = useState(messagesTest);
    const [lastMessage, setLastMessage] = useState(null);
    const [firstUserReply, setFirstUserReply] = useState(true);
    const [idsMessages, setIdsMessages] = useState([]);

    // variables para feedback like, dislike
    const [showFeedBackModal, setShowFeedBackModal] = useState(false);
    const [showThanksModal, setShowThanksModal] = useState(false);
    const [currentFeedbackMessage, setCurrentFeedbackMessage] = useState(null);
    const [feedbackActivate, setFeedbackActivate] = useState(false);

    // Hooks
    const {featureFlags} = useContext(FeatureFlagsContext);
    const {pushState} = useContext(PushStateContext);
    const {
      remoteConfig: {gpt3Diary},
    } = useContext(RemoteConfigContext);

    // Imperative handlers
    useImperativeHandle(ref, () => ({
      finishConversation: () => {
        console.log('ChaMainStreaming finishConversation');
        setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
        console.log('End text stream socket');
        console.log(emitEndConversation);
        emitEndConversation();
      },
    }));

    // socket
    const {
      socket,
      initializeSocket,
      emitText,
      disconnect,
      emitEndConversation,
      errorAttempts,
    } = useSocket({
      moduleType: type,
      conversationFlow: conversationFlowName,
    });

    const initialize = async () => {
      await initializeSocket();
      //await emitStartAudio();
    };
    useEffect(() => {
      if (messages?.length <= 1) {
        initialize();
        return () => {};
      }
    }, []);

    /* useEffect para manejar los eventos del socket */
    useEffect(() => {
      if (!socket) return;

      const socketInitTime = new Date();

      if (messages?.length <= 1) {
        console.log('socket', socketInitTime);
        socket.on('connect', () => {
          console.log('connect');
          const timeIt = (socketInitTime - new Date()) / 1000;
          console.log('Socket conectado at', new Date());
          console.log(`Socket conectado en ${timeIt} secs`);
          setChatStateResponse(CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE);
          setStreamingStatus(STREAMING_STATUS.CONNECTED);
        });
      }

      socket.on('unauthorized', error => {
        console.log('unauthorized', error);
        setChatStateResponse(CHAT_STATE_RESPONSE.ERROR);
        setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
      });

      socket.on('vs_response_text', handleResponseInStreaming);

      socket.on('vs_response', handleResponseComplete);

      socket.on('vs_finish_conversation', handleFinishConversation);

      socket.on('error', async error => {
        console.error('Error chat stream socket:');
        console.error(error);
        try {
          await logSimpleEvent('chat_stream_error', {
            error: JSON.stringify(error),
            type: 'error',
          });
        } catch (e) {
          Sentry.captureException(e);
        }
      });

      socket.on('reconnect_attempt', attempt => {
        console.error('Reconnect attempt socket: ' + attempt);

        if (attempt === 1) {
          logSimpleEvent('chat_stream_error', {
            attempt: attempt,
            type: 'attempt',
          });
        }

        if (attempt >= errorAttempts) {
          logSimpleEvent('chat_stream_error', {
            attempt: attempt,
            type: 'attempt',
          });
          setChatStateResponse(CHAT_STATE_RESPONSE.NOT_RECONNECTED);
          setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
          disconnect();
        } else {
          setChatStateResponse(CHAT_STATE_RESPONSE.RECONNECTING);
        }
      });

      socket.on('reconnect', attempt => {
        console.info(
          'Chat Stream: Reconectado despues de los siguientes intentos : ' +
            attempt,
        );
        setStreamingStatus(STREAMING_STATUS.CONNECTED);
      });
      socket.on('disconnect', (reason, details) => {
        if (!socket.active) {
          if (streamingStatus === STREAMING_STATUS.CONNECTED) {
            initialize();
          }
          setChatStateResponse(CHAT_STATE_RESPONSE.NOT_RECONNECTED);
          setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
          console.log('Socket disconnect');
          console.log('Reason: ', reason);
          console.log('Details: ', details);
        }
      });
      // return () => {
      //   disconnect();
      // };
    }, [socket]);

    useEffect(() => {
      setFeedbackActivate(
        isModuleActive(MODULES.DIARY_FEEDBACK, featureFlags) &&
          chatModule === CHAT_MODULE.GPT,
      );
    }, [featureFlags]);

    const handleEmitText = async data => {
      await emitTextExercise(data);
    };

    useEffect(() => {
      if (conversationFlowName !== null && !isExercise) {
        const data = {
          type: CHAT_TYPES.ACTION,
          content: {
            action: 'FLOW_UPDATE',
            payload: {
              flowName: conversationFlowName,
              message: title,
            },
          },
        };

        handleEmitText(data);
        return () => {};
      }
    }, [conversationFlowName, isExercise]);

    function handleResponseInStreaming(message) {
      /*
       * id: string,
       * role: 'user' | 'bot',
       * content: string,
       * finish: boolean,
       * */
      if (message.role === 'user') return;

      setMessages(current => {
        const lastMessage = current[0];
        if (!lastMessage?.isStreaming) {
          return [{...message, isStreaming: true, bot: true}, ...current];
        }
        const newMessage = {
          ...lastMessage,
          content: lastMessage.content + message.content,
        };
        return [newMessage, ...current.slice(1)];
      });
    }

    async function handleResponseComplete(data) {
      console.log('🦅🦅🦅🦅', data);
      /*
       * id: string,
       * role: 'user' | 'bot',
       * content: string,
       * finishMessage: boolean,
       * extraData: {
       *  multimedia: {
       *      contentUrl: string,
       *   }
       * }
       * */
      const isAudio = data?.extraData?.multimedia?.contentUrl;

      sendEvent('2.A MessageReceived', {
        content: data?.content,
        role: data?.role,
        bot: data?.role === 'bot',
        messageType: isAudio ? 'audio' : 'text',
        conversationFlow:
          conversationFlowName === null
            ? gpt3Diary?.conversationFlowName
            : conversationFlowName,
        audioData: isAudio
          ? {
              ...data?.extraParams,
              ...data?.extraData,
            }
          : null,
      });

      if (data?.role === 'user') {
        const newMessage = {
          ...data,
          isStreaming: false,
          bot: false,
        };
        setMessages(current => [newMessage, ...current]);
        return;
      }

      setIdsMessages(currentIds => {
        console.log(`CS: Lista de  Ids: ${currentIds}`);

        // Verifica si el id del mensaje ya existe en el estado
        if (currentIds.includes(data.id)) {
          setChatStateResponse(
            data?.finishMessage
              ? CHAT_STATE_RESPONSE.FINISH_MESSAGE
              : CHAT_STATE_RESPONSE.EXPECTING_RESPONSE,
          );
          console.log(`CS: El mensaje con id ${data.id} ya existe.`);
          return currentIds;
        }
        // Si el id del mensaje no existe, lo agrega al estado

        // remove first and replace with data

        setMessages(current => {
          const newMessage = {
            ...data,
            isStreaming: false,
            bot: true,
            feedback: {
              //feedback de mensajes por default
              like: false,
              dislike: false,
              comment: null,
            },
            isLastMessage: true,
          };
          const messagesNotLast = current?.map(msge => ({
            ...msge,
            isLastMessage: false,
          }));
          setLastMessage(newMessage);
          return [newMessage, ...messagesNotLast.slice(1)];
        });

        setChatStateResponse(
          data?.finishMessage
            ? CHAT_STATE_RESPONSE.FINISH_MESSAGE
            : CHAT_STATE_RESPONSE.EXPECTING_RESPONSE,
        );

        console.log(`El mensaje con id ${data.id} se agrega a la lista.`);

        return [data.id, ...currentIds];
      });
    }

    function handleFinishConversation() {
      console.log('🐔🐔🐔🐔🐔');
      setChatStateResponse(CHAT_STATE_RESPONSE.FINISH_MESSAGE);
      setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
    }

    async function handleSendResponse(messageUser) {
      console.log('🐔🐔🐔🐔🐔 SEnd RESPONSE', messageUser);
      /*
       * type: 'text' | 'audio',
       * format: 'base64',
       * content: string,
       *
       * */
      emitText(messageUser, response => {
        console.log('Response emitText: ', response);
        if (!response) {
          console.log('Ocurrio un error en emitText');
          setChatStateResponse(CHAT_STATE_RESPONSE.NOT_RECONNECTED);
          setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
          disconnect();
        }
      });

      if (firstUserReply) {
        const sessionID = messageUser && messageUser[0]?.extraData?.session_id;
        sendEvent('1.A UserReply', {
          messageUser,
          sessionID,
        });
        setFirstUserReply(false);
      }
      setChatStateResponse(CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE);
    }

    async function emitTextExercise(data) {
      setMessages(current => {
        if (current?.length > 0) {
          return current?.slice(0, -1);
        }
        return current;
      });

      emitText(data, response => {
        if (!response) {
          console.log('Ocurrio un error en emitText de type action');
          setChatStateResponse(CHAT_STATE_RESPONSE.NOT_RECONNECTED);
          setStreamingStatus(STREAMING_STATUS.DISCONNECTED);
          disconnect();
        }
      });
      setChatStateResponse(CHAT_STATE_RESPONSE.WAITING_FOR_RESPONSE);
    }

    /* Función que regresa si este modulo tiene feedback activado */
    function isFeedbackActive() {
      return (
        isModuleActive(MODULES.DIARY_FEEDBACK, featureFlags) &&
        chatModule === CHAT_MODULE.GPT
      );
    }

    // Funcion para evento de rudder
    async function sendEvent(step, params) {
      logRudderEvent({
        name: 'conversationFlow',
        step: step,
        screen: 'ChatGenerico',
        extraFrom: {
          component: 'ChatGenerico',
        },
        pushState,
        extraProperties: {...params},
      });
    }

    // Funcion para enviar a llamada en stream
    async function sendToCall() {
      await logSimpleEvent('call_start');
      navigation.navigate(ROUTE_NAMES.voice, {disconnectSocket: disconnect});
    }

    function modifyMessageFeedback(newMessage) {
      // map
      const messageIndex = messages.findIndex(msg => msg.id === newMessage?.id);
      if (messageIndex !== -1) {
        const newMessages = [...messages];
        newMessages[messageIndex] = newMessage;
        setMessages(newMessages);
      }
    }

    function storageFeedback({
      feedBackType,
      message: messageFromChild,
      comment,
    }) {
      const message = messageFromChild || currentFeedbackMessage;
      storageFeedbackMessage({
        feedBackType,
        message,
        comment,
        modifyMessageFeedback,
        conversationFlow,
        pushState,
        setShowThanksModal,
      });
    }

    function openComment({message}) {
      if (message?.feedback?.comment) {
        return;
      }
      setCurrentFeedbackMessage(message);
      setShowFeedBackModal(true);
    }

    return (
      <>
        <ChatContainer>
          <ChatSkeletonStreaming
            messages={messages}
            chatStateResponse={chatStateResponse}
            onSendResponse={handleSendResponse}
            lastMessage={lastMessage}
            onFinishConversation={onFinishConversation}
            chatModule={chatModule}
            sendToCall={sendToCall}
            isFeedbackShow={feedbackActivate}
            storageFeedback={storageFeedback}
            openComment={openComment}
            finishText={t('chat:finish')}
            conversationFlow={conversationFlowName}
            showChipsQuestion={showChipsQuestion}
            setShowChipsQuestion={setShowChipsQuestion}
          />
          <ModalFeedback
            onFeedback={storageFeedback}
            showModal={showFeedBackModal}
            setShowModal={setShowFeedBackModal}
          />
          <ModalThanks
            showModal={showThanksModal}
            setShowModal={setShowThanksModal}
          />
        </ChatContainer>
      </>
    );
  },
);

export default ChatMainStreaming;
